import '../../App.scss';
import '../../css/modals.scss';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { Button, Col, Row, Alert } from 'react-bootstrap';
import { serverPost, serverPatch } from '../../helpers/server';
const _ = require("lodash");

function InvoiceSubdetailsModal(props) {
    if (!props.show) {
        return null;
    }
    return (
        <BaseModal size="lg" {...props} disableEnforceFocus>
            <BaseModal.Header>
                <BaseModal.Title>{props.title}</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm initialFormFields={props} onSubmit={(data) => {
                props.onUpdate(props.name, data[props.name])
                props.onClose();
            }}>
                <BaseModal.Body>
                    {
                        props.name === "notes" &&
                            <Alert>Notes will appear on the invoice under the pricing table for the customers to view.</Alert>
                    }
                    <Row>
                        <BaseForm.Input colSpan={12} name={props.name} type="editor"
                            tokens={[
                                '{companyName}',
                                '{address1}',
                                '{groupName}',
                                '{to}', //Change this to be more understandable ie: CustomerName
                                '{customerEmail}',
                                '{customerPhone}',
                                '{customerAddress}'
                            ]}/>
                    </Row>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Row>
                        <Col md="12">
                            <Button variant="link" onClick={() => props.onClose(false)}>Cancel</Button>
                            <SubmitButton variant="primary">Save</SubmitButton>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default InvoiceSubdetailsModal;
